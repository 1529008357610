














import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import ManagerDepositoryModule from '@/store/modules/manager/depository'

@Component({
  components: {
    LinkTabs,
  },
})
export default class DepositoryCoursesItem extends Mixins(NotifyMixin, PermissionsMixin) {

  private get tabs() {
    return [
      {
        name: 'Полезные материалы',
        to: { name: 'manager.bank.depository.courses.item.materials' },
      },
      {
        name: 'Учебные файлы',
        to: { name: 'manager.bank.depository.courses.item.files' },
      },
    ]
  }

  private get courseID () {
    return +this.$route.params.courseID
  }

  private get course () {
    return ManagerDepositoryModule.course
  }

  private get breadcrumbs () {
    return [{ name: 'База знаний', path: '/manager/bank/depository' }]
  }

  private mounted () {
    this.fetchCourse()
  }

  private fetchCourse () {
    ManagerDepositoryModule.fetchCourse(this.courseID)
      .catch((error) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.bank.depository.courses' })
      })
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.course ? this.course.name : 'База знаний',
    }
  }
}
